import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicFillComplaintOnline = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query PrismicFillComplaintOnline {
      allPrismicFillcomplaintonline(filter: {tags: {in: "fill_complaint_online"}}) {
        nodes {
          lang
          data {
            navtext
            title {
              text
            }
            accidenthappen
            accordingposition
            assuretext {
              richText
            }
            attachicon {
              url
            }
            attachtext {
              richText
            }
            authorizedperson
            buttontext
            carmake
            carregistrationnumber
            complaintconcerns {
              concerns
            }
            expertconclusion
            indicatedetails
            nameinsurancecompany
            numberplate_
            otherdriver
            propertyowner
            rightdescription {
              richText
            }
            rightdescription_1 {
              richText
            }
            rightdescription_2 {
              richText
            }
            righttitle {
              text
            }
            righttitle_1 {
              text
            }
            wc_title {
              text
            }
            wc_title_1 {
              text
            }
            wc_title_2 {
              text
            }
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicFillcomplaintonline.nodes.filter(item => item.lang === newLang)[0].data)
};

const sanitize = (response) => {
    return {
        navText: response.navtext,
        title: response.title.text,
        accidentHappen: response.accidenthappen,
        accordingPosition: response.accordingposition,
        authorizedPerson: response.authorizedperson,
        buttonText: response.buttontext,
        carMake: response.carmake,
        carRegistrationNumber: response.carregistrationnumber,
        expertConclusion: response.expertconclusion,
        indicateDetails: response.indicatedetails,
        nameInsuranceCompany: response.nameinsurancecompany,
        numberplate: response.numberplate_,
        otherDriver: response.otherdriver,
        propertyOwner: response.propertyowner,
        assureText: response.assuretext.richText,
        rightDescription: response.rightdescription.richText,
        rightDescription_1: response.rightdescription_1.richText,
        rightDescription_2: response.rightdescription_2.richText,
        rightTitle: response.righttitle.text,
        righTtitle_1: response.righttitle_1.text,
        wc_title: response.wc_title.text,
        wc_title_1: response.wc_title_1.text,
        wc_title_2: response.wc_title_2.text,
        complaintConcerns: response.complaintconcerns.map(concern => concern.concerns),
        attachIcon: response.attachicon.url,
        attachText: response.attachtext.richText,
    }
}

export default usePrismicFillComplaintOnline;