import * as React from "react";
import {
  HStack,
  Stack,
  Checkbox,
  Text,
  Box,
  Button,
  Image,
} from "@chakra-ui/react";
import { InputField } from "../../components/inputfield";
import RichText from "../PrismicRichText";

const WhoComplaint = ({ data }) => {
  return (
    <Box w="full">
      <Box maxW="42rem">
        <Text mb="24px" fontSize="1rem">
          {data.wc_title}
        </Text>
        <Stack
          mb="10"
          spacing={{ base: "4", sm: "5" }}
          direction={{ base: "column", sm: "row" }}
        >
          <InputField label={data.propertyOwner} />
          <InputField label={data.authorizedPerson} />
        </Stack>
        <Text fontSize="1rem" mb="6">
          {data.wc_title_1}
        </Text>
        <Stack
          mb="1rem"
          spacing={{ base: "4", sm: "5" }}
          direction={{ base: "column", sm: "row" }}
        >
          <InputField label={data.numberplate} />
          <InputField label={data.otherDriver} />
        </Stack>
        <Stack
          mb="1rem"
          spacing={{ base: "4", sm: "5" }}
          direction={{ base: "column", sm: "row" }}
        >
          <InputField label={`${data.carRegistrationNumber} *`} />
          <InputField label={`${data.carMake} *`} />
        </Stack>
        <InputField
          label={`${data.nameInsuranceCompany} *`}
          mb="1rem"
        />
        <Box maxW="20.31rem" mb="2.5rem">
          <InputField label={data.accidentHappen} />
        </Box>
        <Text fontSize="1rem" mb="6">
          {data.wc_title_2} *
        </Text>
        <Stack
          mb="1rem"
          spacing={{ base: "4", sm: "5" }}
          direction={{ base: "column", sm: "row" }}
        >
          <InputField label={data.expertConclusion} />
          <InputField label={data.accordingPosition} />
        </Stack>
        <InputField
          label={data.indicateDetails}
        />
        <HStack mt="6.25rem" spacing="0.875rem" alignItems="flex-start" p="0">
          <Box w="2rem"   >
            <Image
                objectFit="cover"
                borderRadius="20px"
                width={{base:'100%', sm: '272px'}}
                src={data.attachIcon}
            />
          </Box>
          <Box fontSize="sm" color="grey.100">
             <RichText text={data.attachText}/>
          </Box>
        </HStack>
        <HStack mt="1.875rem" alignItems="flex-start" mb="2.8rem" >
          <Checkbox />
          <Box fontSize="sm" color="grey.100" px="5px">
             <RichText text={data.assureText}/>
          </Box>
        </HStack>
        <Button w="full" variant="green" color="white">{data.buttonText}</Button>
      </Box>
      
    </Box>
  );
};
export default WhoComplaint;
