import * as React from "react";
import { graphql } from "gatsby";
import {
  Container,
  Heading,
  Box,
  Stack,
  Text,
  Button,
} from "@chakra-ui/react";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import { useTranslation } from "react-i18next";
import WhoComplaint from "../../components/fillComplaintOnline/WhoComplaint";
import usePrismicFillComplaintOnline from "../../hooks/usePrismicFillComplaintOnline";
import RichText from "../../components/PrismicRichText";

const FillComplaintClaim = () => {
  const { language } = useTranslation();
  const data = usePrismicFillComplaintOnline()
  return (
    <Main>
      <Container pb="12.5rem" maxW="7xl">
        <Seo
          title={data.title}
          description={data.title}
          link={`https://www.fsm.am/${language}/submit-claim`}
        />
        <SmallNav>
          {data.navText}
        </SmallNav>
        <Heading
          as="h1"
          fontSize={{ base: "2xl", sm: "3xl" }}
          fontWeight="900"
          mt="5"
          mb="3.75rem"
        >
          {data.title}
        </Heading>
        <Stack direction={{ base: "column", md: "row" }} spacing={{base:"2.5rem",sm:"8rem"}}>
          <WhoComplaint data={data} />
          <Box maxW="20.25rem">
            <Box
              borderLeft={{ base: "none", md: "1px solid #dddddd" }}
              borderTop={{ base: "1px solid #dddddd", md: "none" }}
              p="6"
            >
              <Text as="b">
                {data.rightTitle}
              </Text>
              <Box fontSize="sm" color="grey.100" mb="1rem" mt="1rem">
                <RichText text={data.rightDescription}/>
              </Box>
              <Button variant="text">{data.righTtitle_1}</Button>
              <Box fontSize="sm" color="grey.100" mb="1rem">
                <RichText text={data.rightDescription_1}/>
              </Box>
              <Box fontSize="sm" color="grey.100" mb="1rem">
                 <RichText text={data.rightDescription_2}/>
              </Box>
              <Text fontSize="sm">
                {data.complaintConcerns?.map((concern, idx) => (
                    <span key={`concern-${concern}-${idx}`}>
                      {concern} <br/>
                    </span>
                ))}
              </Text>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Main>
  );
};

export default FillComplaintClaim;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
